










































































import { AddressInput, BackButton, Checkbox, InputBox, List, ListItem, LoadingButton, NavigationBar, PhoneInput, PriceInput, Spinner, Toolbar, VATInput } from "@goodless/components";
import { Session } from "@goodless/networking";
import { Category, PriceList, PriceListSettings, PrivatePriceList, ProductPrices, Version } from "@goodless/structures";
import { ArrayDecoder, AutoEncoderPatchType, Decoder, PatchableArray, PatchableArrayAutoEncoder, patchContainsChanges } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView, Validator } from "@simonbackx/simple-error-forms";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";


@Component({
    components: {
        GeneralErrorsView,
        InputBox,
        Spinner,
        Toolbar,
        List,
        ListItem,
        NavigationBar,
        LoadingButton,
        AddressInput,
        PriceInput,
        PhoneInput,
        BackButton,
        VATInput,
        Checkbox
    }
})
export default class PriceListView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    loading = false
    loadingProducts = true
    categories: Category[] = []

    @Prop({ required: true })
    priceList: PrivatePriceList
    priceListPatch = PrivatePriceList.patch({})

    validator = new Validator()

    @Prop({ required: true })
    isNew: boolean

    @Prop({ required: true })
    callback: (patch: PatchableArrayAutoEncoder<PrivatePriceList>) => Promise<void>

    get patchedPriceList() {
        return this.priceList.patch(this.priceListPatch)
    }

    created() {
        this.loadProducts().catch(console.error)
    }

    get products() {
        return this.categories.flatMap(c => c.products)
    }

    async loadProducts() {
        this.loadingProducts = true

        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/categories",
                decoder: new ArrayDecoder(Category as Decoder<Category>)
            })
            this.categories = response.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loadingProducts = false
    }
   
    async save() {
        if (this.loading) {
            return
        }
        this.loading = true;
        this.errorBox = null;

        try {
            if (!await this.validator.validate()) {
                this.loading = false
                return
            }
            const arr: PatchableArrayAutoEncoder<PrivatePriceList> = new PatchableArray()
            if (this.isNew) {
                arr.addPut(this.patchedPriceList)
            } else {
                this.priceListPatch.id = this.priceList.id
                arr.addPatch(this.priceListPatch)
            }
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    async deleteMe() {
        if (this.loading) {
            return
        }

        if (!confirm("Ben je zeker dat je deze prijslijst wilt verwijderen?")) {
            return
        }

        this.loading = true;
        this.errorBox = null;

        try {
            const arr: PatchableArrayAutoEncoder<PrivatePriceList> = new PatchableArray()
            if (this.isNew) {
                throw new Error("Not possible")
            } else {
                arr.addDelete(this.patchedPriceList.id)
            }
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    shouldNavigateAway() {
        if (patchContainsChanges(this.priceListPatch, this.priceList, { version: Version })) {
            if (confirm("Ben je zeker dat je terug wilt zonder op te slaan?")) {
                return true
            } else {
                return false
            }
        }
        return true
    }

    addSettingsPatch(patch: AutoEncoderPatchType<PriceListSettings>) {
        this.priceListPatch = this.priceListPatch.patch({
            settings: patch
        })
    }

    get name() {
        return this.patchedPriceList.settings.name
    }

    set name(name: string) {
        this.addSettingsPatch(PriceListSettings.patch({
            name
        }))
    }

    get description() {
        return this.patchedPriceList.settings.description
    }

    set description(description: string) {
        this.addSettingsPatch(PriceListSettings.patch({
            description
        }))
    }

    get useUrl() {
        return this.patchedPriceList.url !== null
    }

    set useUrl(useUrl: boolean) {
        if (this.useUrl === useUrl) {
            return
        }
        this.priceListPatch = this.priceListPatch.patch({
            url: useUrl ? "" : null
        })
    }

    get url() {
        return this.patchedPriceList.url ?? ""
    }

    set url(url: string) {
        this.priceListPatch = this.priceListPatch.patch({
            url
        })
    }

    // Prices
    hasCustomPrice(id: string) {
        return this.patchedPriceList.settings.priceList?.has(id) ?? false
    }

    // Prices
    setCustomPrice(id: string, custom: boolean) {
        if (this.hasCustomPrice(id) === custom) {
            return
        }
        if (!custom) {
            if (!this.patchedPriceList.settings.priceList) {
                return
            }
            const priceList = new Map(this.patchedPriceList.settings.priceList)
            priceList.delete(id)
            this.addSettingsPatch(PriceListSettings.patch({ priceList }))
        } else {
            let priceList: PriceList = new Map()
            if (this.patchedPriceList.settings.priceList) {
                priceList = new Map(this.patchedPriceList.settings.priceList)
            }
            const product = this.products.find(p => p.id === id)
            priceList.set(id, product?.prices?.patch({}) ?? ProductPrices.create({}))
            this.addSettingsPatch(PriceListSettings.patch({ priceList }))
        }
    }

    getProductWarranty(id: string) {
        let priceList: PriceList = new Map()
        if (this.patchedPriceList.settings.priceList) {
            priceList = new Map(this.patchedPriceList.settings.priceList)
        }
        return priceList.get(id)?.warranty ?? 0
    }

    setProductWarranty(id: string, warranty: number) {
        let priceList: PriceList = new Map()
        if (this.patchedPriceList.settings.priceList) {
            priceList = new Map(this.patchedPriceList.settings.priceList)
        }
        const existing = priceList.get(id) ?? ProductPrices.create({})
        priceList.set(id, existing.patch({ warranty }))
        this.addSettingsPatch(PriceListSettings.patch({ priceList }))
    }

    getProductPrice(id: string) {
        let priceList: PriceList = new Map()
        if (this.patchedPriceList.settings.priceList) {
            priceList = new Map(this.patchedPriceList.settings.priceList)
        }
        return priceList.get(id)?.unitPrice ?? 0
    }

    setProductPrice(id: string, unitPrice: number) {
        let priceList: PriceList = new Map()
        if (this.patchedPriceList.settings.priceList) {
            priceList = new Map(this.patchedPriceList.settings.priceList)
        }
        const existing = priceList.get(id) ?? ProductPrices.create({})
        priceList.set(id, existing.patch({ unitPrice }))
        this.addSettingsPatch(PriceListSettings.patch({ priceList }))
    }
}
