

















































































import { Category, Image, ProductPrices, ProductType, ResolutionFit, ResolutionRequest, Version } from "@goodless/structures";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Spinner, List, ListItem, NavigationBar, InputBox, Toolbar, LoadingButton, NumberInput, PriceInput, UploadImageButton, BackButton } from "@goodless/components";
import { PatchableArray, PatchableArrayAutoEncoder, patchContainsChanges } from "@simonbackx/simple-encoding";
import { Product } from "@goodless/structures";

@Component({
    components: {
        GeneralErrorsView,
        InputBox,
        Spinner,
        Toolbar,
        List,
        ListItem,
        NavigationBar,
        LoadingButton,
        NumberInput,
        PriceInput,
        UploadImageButton,
        BackButton
    }
})
export default class ProductView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    loading = false

    @Prop({ required: true })
    product: Product
    productPatch = Product.patch({})

    @Prop({ required: true })
    isNew: boolean

    @Prop({ required: true })
    callback: (patch: PatchableArrayAutoEncoder<Product>) => Promise<void>

    get patchedProduct() {
        return this.product.patch(this.productPatch)
    }

    get name() {
        return this.patchedProduct.name
    }

    set name(name: string) {
        this.productPatch = this.productPatch.patch({ name })
    }

    get description() {
        return this.patchedProduct.description
    }

    set description(description: string) {
        this.productPatch = this.productPatch.patch({ description })
    }

    get soldPer() {
        return this.patchedProduct.soldPer
    }

    set soldPer(soldPer: number) {
        this.productPatch = this.productPatch.patch({ soldPer })
    }

    get ProductType() {
        return ProductType
    }

    get type() {
        return this.patchedProduct.type
    }

    set type(type: ProductType) {
        this.productPatch = this.productPatch.patch({ type })
    }

    get unitPrice() {
        return this.patchedProduct.prices.unitPrice
    }

    set unitPrice(unitPrice: number) {
        this.productPatch = this.productPatch.patch({ prices: ProductPrices.patch({ unitPrice }) })
    }

    get warranty() {
        return this.patchedProduct.prices.warranty
    }

    set warranty(warranty: number) {
        this.productPatch = this.productPatch.patch({ prices: ProductPrices.patch({ warranty }) })
    }
   
    async save() {
        if (this.loading) {
            return
        }
        this.loading = true;
        this.errorBox = null;

        try {
            const arr: PatchableArrayAutoEncoder<Product> = new PatchableArray()
            if (this.isNew) {
                arr.addPut(this.patchedProduct)
            } else {
                this.productPatch.id = this.product.id
                arr.addPatch(this.productPatch)
            }
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    async deleteMe() {
        if (this.loading) {
            return
        }
        this.loading = true;
        this.errorBox = null;

        try {
            const arr: PatchableArrayAutoEncoder<Product> = new PatchableArray()
            if (this.isNew) {
                throw new Error("Not possible")
            } else {
                arr.addDelete(this.patchedProduct.id)
            }
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    shouldNavigateAway() {
        if (patchContainsChanges(this.productPatch, this.product, { version: Version })) {
            if (confirm("Ben je zeker dat je terug wilt zonder op te slaan?")) {
                return true
            } else {
                return false
            }
        }
        return true
    }

    get resolutions(): ResolutionRequest[] {
        return [
            ResolutionRequest.create({
                width: 500,
                fit: ResolutionFit.Cover
            }),
            ResolutionRequest.create({
                width: 1500,
                fit: ResolutionFit.Cover
            })
        ]
    }

    get images() {
        return this.patchedProduct.images
    }

    getImageSrc(image: Image) {
        return image.getPathForSize(200, 200)
    }

    addImage(image: Image) {
        const p = Product.patch({})
        p.images.addPut(image)
        this.productPatch = this.productPatch.patch(p)
    }

    deleteImage(image: Image) {
        const p = Product.patch({})
        p.images.addDelete(image.id)
        this.productPatch = this.productPatch.patch(p)
    }
    
}
