






import { AuthenticatedView, PromiseView } from "@goodless/containers";
import { load } from "@goodless/i18n";
import { Session } from "@goodless/networking";
import { ComponentWithProperties, HistoryManager, ModalStackComponent, NavigationController, SplitViewController } from "@simonbackx/vue-app-navigation";
import { Component, Vue } from "vue-property-decorator";

import RootMenuView from "./views/menu/RootMenuView.vue";
import LoginView from "./views/welcome/LoginView.vue";

@Component({
    components: {
        ModalStackComponent
    },
})
export default class App extends Vue {
    root = new ComponentWithProperties(PromiseView, {
        promise: async () => {
            await load()

            return new ComponentWithProperties(AuthenticatedView, {
                root: new ComponentWithProperties(PromiseView, {
                    promise: async () => {
                        try {
                            if (Session.shared.user && Session.shared.user.permissions && Session.shared.user.permissions?.isAdmin) {
                                // Admins always go to the root menu
                                return new ComponentWithProperties(ModalStackComponent, {
                                    root: new ComponentWithProperties(SplitViewController, {
                                        root:  new ComponentWithProperties(NavigationController, { 
                                            root: new ComponentWithProperties(RootMenuView, {})
                                        })
                                    })
                                })
                            }
                            throw new Error("Not authorized")

                        } catch (e) {
                            console.error(e)
                            Session.shared.temporaryLogout()
                            throw e
                        }
                    }
                }),
                loginRoot:  new ComponentWithProperties(LoginView, {})
            });
        }
    });
    mounted() {
        HistoryManager.activate();
    }
}
