





























import { InputBox, List, ListItem, Spinner } from "@goodless/components"
import { Session } from "@goodless/networking";
import { ComponentWithProperties, HistoryManager, NavigationController, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

import CustomersView from "../customers/CustomersView.vue";
import OrdersView from "../orders/OrdersView.vue";
import PriceListsView from "../price-lists/PriceListsView.vue";
import ProductsView from "../products/ProductsView.vue";
import CodesView from "../codes/CodesView.vue";

@Component({
    components: {
        InputBox,
        List,
        ListItem,
        Spinner
    },
})
export default class RootMenuView extends Mixins(NavigationMixin) {
    mounted() {
        HistoryManager.setUrl("/")

        if (!this.splitViewController!.shouldCollapse()) {
            this.openProducts(false)
        }
    }

    openCustomers(animated = true) {
        this.showDetail(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(CustomersView)
        }).setAnimated(animated))
    }

    openProducts(animated = true) {
        this.showDetail(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(ProductsView)
        }).setAnimated(animated))
    }

    openCodes(animated = true) {
        this.showDetail(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(CodesView)
        }).setAnimated(animated))
    }

    openOrders(animated = true) {
        this.showDetail(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(OrdersView)
        }).setAnimated(animated))
    }

    openPriceLists(animated = true) {
        this.showDetail(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(PriceListsView)
        }).setAnimated(animated))
    }

    logout() {
        Session.shared.logout()
    }

}
