





























































































































import { AddressInput, BackButton, Checkbox, InputBox, List, ListItem, LoadingButton, NavigationBar, PhoneInput, PriceInput, Spinner, Toolbar, VATInput } from "@goodless/components";
import { Session } from "@goodless/networking";
import { Address, Business, Category, ContactPerson, CustomerPrivateSettings, CustomerSettings, PriceList, PrivateCustomer, PrivatePriceList, ProductPrices, User, Version } from "@goodless/structures";
import { ArrayDecoder, AutoEncoderPatchType, Decoder, PatchableArray, PatchableArrayAutoEncoder, patchContainsChanges } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView, Validator } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import UserView from "./UserView.vue";

@Component({
    components: {
        GeneralErrorsView,
        InputBox,
        Spinner,
        Toolbar,
        List,
        ListItem,
        NavigationBar,
        LoadingButton,
        AddressInput,
        PriceInput,
        PhoneInput,
        BackButton,
        VATInput,
        Checkbox
    }
})
export default class CustomerView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    loading = false
    loadingProducts = true

    priceLists: PrivatePriceList[] = []
    loadingPriceLists = false
    categories: Category[] = []

    @Prop({ required: true })
    customer: PrivateCustomer
    customerPatch = PrivateCustomer.patch({})

    validator = new Validator()

    @Prop({ required: true })
    isNew: boolean

    @Prop({ required: true })
    callback: (patch: PatchableArrayAutoEncoder<PrivateCustomer>) => Promise<void>

    get patchedCustomer() {
        return this.customer.patch(this.customerPatch)
    }

    get title() {
        return this.patchedCustomer.name
    }

    created() {
        this.loadProducts().catch(console.error)
        this.loadPriceLists().catch(console.error)
    }

    get products() {
        return this.categories.flatMap(c => c.products)
    }

    get users() {
        return this.patchedCustomer.users
    }

    async loadProducts() {
        this.loadingProducts = true

        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/categories",
                decoder: new ArrayDecoder(Category as Decoder<Category>)
            })
            this.categories = response.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loadingProducts = false
    }

    async loadPriceLists() {
        this.loadingPriceLists = true

        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/price-lists",
                decoder: new ArrayDecoder(PrivatePriceList as Decoder<PrivatePriceList>)
            })
            this.priceLists = response.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loadingPriceLists = false
        
    }
   
    async save() {
        if (this.loading) {
            return
        }
        this.loading = true;
        this.errorBox = null;

        try {
            if (!await this.validator.validate()) {
                this.loading = false
                return
            }
            const arr: PatchableArrayAutoEncoder<PrivateCustomer> = new PatchableArray()
            if (this.isNew) {
                arr.addPut(this.patchedCustomer)
            } else {
                this.customerPatch.id = this.customer.id
                arr.addPatch(this.customerPatch)
            }
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    async deleteMe() {
        if (this.loading) {
            return
        }

        if (!confirm("Ben je zeker dat je deze klant wilt verwijderen?")) {
            return
        }

        this.loading = true;
        this.errorBox = null;

        try {
            const arr: PatchableArrayAutoEncoder<PrivateCustomer> = new PatchableArray()
            if (this.isNew) {
                throw new Error("Not possible")
            } else {
                arr.addDelete(this.patchedCustomer.id)
            }
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    shouldNavigateAway() {
        if (patchContainsChanges(this.customerPatch, this.customer, { version: Version })) {
            if (confirm("Ben je zeker dat je terug wilt zonder op te slaan?")) {
                return true
            } else {
                return false
            }
        }
        return true
    }

    addSettingsPatch(patch: AutoEncoderPatchType<CustomerSettings>) {
        this.customerPatch = this.customerPatch.patch({
            settings: patch
        })
    }

    addPrivateSettingsPatch(patch: AutoEncoderPatchType<CustomerPrivateSettings>) {
        this.customerPatch = this.customerPatch.patch({
            privateSettings: patch
        })
    }

    get name() {
        return this.patchedCustomer.settings.business?.name ?? ""
    }

    set name(name: string) {
        this.addSettingsPatch(CustomerSettings.patch({
            business: Business.patch(({
                name
            }))
        }))
    }

    addBusiness() {
        this.addSettingsPatch(CustomerSettings.patch({
            business: Business.create(({}))
        }))
    }

    addContact() {
        this.addSettingsPatch(CustomerSettings.patch({
            contact: ContactPerson.create(({
                name: this.users[0] ? (this.users[0].firstName+" "+this.users[0].lastName) : ""
            }))
        }))
    }

    deleteContact() {
        this.addSettingsPatch(CustomerSettings.patch({
            contact: null
        }))
    }

    deleteBusiness() {
        this.addSettingsPatch(CustomerSettings.patch({
            business: null
        }))
    }

    get VATNumber() {
        return this.patchedCustomer.settings.business?.VATNumber ?? ""
    }

    set VATNumber(VATNumber: string | null) {
        this.addSettingsPatch(CustomerSettings.patch({
            business: Business.patch(({
                VATNumber
            }))
        }))
    }

    get phone() {
        return this.patchedCustomer.settings.business?.phone ?? ""
    }

    set phone(phone: string) {
        this.addSettingsPatch(CustomerSettings.patch({
            business: Business.patch(({
                phone
            }))
        }))
    }

    get address() {
        return this.patchedCustomer.settings.business?.address ?? Address.create({})
    }

    set address(address: Address) {
        this.addSettingsPatch(CustomerSettings.patch({
            business: Business.patch(({
                address
            }))
        }))
    }

    get contactPersonPhone() {
        return this.patchedCustomer.settings.contact?.phone ?? ""
    }

    set contactPersonPhone(phone: string) {
        this.addSettingsPatch(CustomerSettings.patch({
            contact: ContactPerson.patch(({
                phone
            }))
        }))
    }


    get contactPersonName() {
        return this.patchedCustomer.settings.contact?.name ?? ""
    }

    set contactPersonName(name: string) {
        this.addSettingsPatch(CustomerSettings.patch({
            contact: ContactPerson.patch(({
                name
            }))
        }))
    }

    

    // Prices
    hasPriceList(id: string) {
        return this.patchedCustomer.priceListIds.includes(id)
    }

    // Prices
    setPriceList(id: string, custom: boolean) {
        if (this.hasPriceList(id) === custom) {
            return
        }
        if (!custom) {
            const p = PrivateCustomer.patch({})
            p.priceListIds.addDelete(id)
            this.customerPatch = this.customerPatch.patch(p)
        } else {
            const p = PrivateCustomer.patch({})
            p.priceListIds.addPut(id)
            this.customerPatch = this.customerPatch.patch(p)
        }
    }

    // Prices
    hasCustomPrice(id: string) {
        return this.patchedCustomer.privateSettings.priceList?.has(id) ?? false
    }

    // Prices
    setCustomPrice(id: string, custom: boolean) {
        if (this.hasCustomPrice(id) === custom) {
            return
        }
        if (!custom) {
            if (!this.patchedCustomer.privateSettings.priceList) {
                return
            }
            const priceList = new Map(this.patchedCustomer.privateSettings.priceList)
            priceList.delete(id)
            this.addPrivateSettingsPatch(CustomerPrivateSettings.patch({ priceList }))
        } else {
            let priceList: PriceList = new Map()
            if (this.patchedCustomer.privateSettings.priceList) {
                priceList = new Map(this.patchedCustomer.privateSettings.priceList)
            }
            const product = this.products.find(p => p.id === id)
            priceList.set(id, product?.prices?.patch({}) ?? ProductPrices.create({}))
            this.addPrivateSettingsPatch(CustomerPrivateSettings.patch({ priceList }))
        }
    }

    getProductWarranty(id: string) {
        let priceList: PriceList = new Map()
        if (this.patchedCustomer.privateSettings.priceList) {
            priceList = new Map(this.patchedCustomer.privateSettings.priceList)
        }
        return priceList.get(id)?.warranty ?? 0
    }

    setProductWarranty(id: string, warranty: number) {
        let priceList: PriceList = new Map()
        if (this.patchedCustomer.privateSettings.priceList) {
            priceList = new Map(this.patchedCustomer.privateSettings.priceList)
        }
        const existing = priceList.get(id) ?? ProductPrices.create({})
        priceList.set(id, existing.patch({ warranty }))
        this.addPrivateSettingsPatch(CustomerPrivateSettings.patch({ priceList }))
    }

    getProductPrice(id: string) {
        let priceList: PriceList = new Map()
        if (this.patchedCustomer.privateSettings.priceList) {
            priceList = new Map(this.patchedCustomer.privateSettings.priceList)
        }
        return priceList.get(id)?.unitPrice ?? 0
    }

    setProductPrice(id: string, unitPrice: number) {
        let priceList: PriceList = new Map()
        if (this.patchedCustomer.privateSettings.priceList) {
            priceList = new Map(this.patchedCustomer.privateSettings.priceList)
        }
        const existing = priceList.get(id) ?? ProductPrices.create({})
        priceList.set(id, existing.patch({ unitPrice }))
        this.addPrivateSettingsPatch(CustomerPrivateSettings.patch({ priceList }))
    }

    addUser() {
        const user = User.create({
            email: ""
        })
        this.present(new ComponentWithProperties(UserView, {
            user,
            isNew: true,
            callback: (patch: PatchableArrayAutoEncoder<User>) => {
                this.customerPatch = this.customerPatch.patch({
                    users: patch
                })
            }
        }).setDisplayStyle("popup"))
    }

    editUser(user: User) {
        this.present(new ComponentWithProperties(UserView, {
            user,
            isNew: false,
            callback: (patch: PatchableArrayAutoEncoder<User>) => {
                this.customerPatch = this.customerPatch.patch({
                    users: patch
                })
            }
        }).setDisplayStyle("popup"))
    }
}
