





























import { BackButton,List, ListItem, NavigationBar, Spinner } from "@goodless/components";
import { Session } from "@goodless/networking";
import { Category } from "@goodless/structures";
import { ArrayDecoder, Decoder, PatchableArrayAutoEncoder } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

import CategoryView from "./CategoryView.vue";

@Component({
    components: {
        GeneralErrorsView,
        Spinner,
        List,
        ListItem,
        NavigationBar,
        BackButton
    }
})
export default class ProductsView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    loading = false
    categories: Category[] = []

    created() {
        this.load().catch(console.error)
    }

    async load() {
        this.loading = true

        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/categories",
                decoder: new ArrayDecoder(Category as Decoder<Category>)
            })
            this.categories = response.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
        
    }

    async patch(patch: PatchableArrayAutoEncoder<Category>) {
        const response = await Session.shared.authenticatedServer.request({
            method: "PATCH",
            path: "/dashboard/categories",
            body: patch,
            decoder: new ArrayDecoder(Category as Decoder<Category>)
        })
        this.categories = response.data
    }

    createCategory() {
        const category = Category.create({})
        this.show(new ComponentWithProperties(CategoryView, {
            category,
            isNew: true,
            callback: async (patch: PatchableArrayAutoEncoder<Category>) => {
                await this.patch(patch)
            }
        }))
    }

    editCategory(category: Category) {
        this.show(new ComponentWithProperties(CategoryView, {
            category,
            isNew: false,
            callback: async (patch: PatchableArrayAutoEncoder<Category>) => {
                await this.patch(patch)
            }
        }))
    }
    
}
