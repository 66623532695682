
































import { BackButton,List, ListItem, NavigationBar, Spinner } from '@goodless/components';
import { Session } from '@goodless/networking';
import { CheckoutCode } from '@goodless/structures';
import { ArrayDecoder, Decoder, PatchableArrayAutoEncoder } from '@simonbackx/simple-encoding';
import { ErrorBox, GeneralErrorsView } from '@simonbackx/simple-error-forms';
import { ComponentWithProperties, NavigationMixin } from '@simonbackx/vue-app-navigation';
import { Component, Mixins } from 'vue-property-decorator';

import CodeView from './CodeView.vue';

@Component({
    components: {
        GeneralErrorsView,
        Spinner,
        List,
        ListItem,
        NavigationBar,
        BackButton
    }
})
export default class ProductsView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    loading = false
    codes: Array<CheckoutCode> = []

    created() {
        this.load().catch(console.error)
    }

    async load() {
        this.loading = true

        try {
            const response = await Session.shared.authenticatedServer.request({
                method: 'GET',
                path: '/dashboard/checkout-codes',
                decoder: new ArrayDecoder(CheckoutCode as Decoder<CheckoutCode>)
            })
            this.codes = response.data
        } catch (error) {
            this.errorBox = new ErrorBox(error)
        }
        this.loading = false

    }

    async patch(patch: PatchableArrayAutoEncoder<CheckoutCode>) {
        const response = await Session.shared.authenticatedServer.request({
            method: 'PATCH',
            path: '/dashboard/checkout-codes',
            body: patch,
            decoder: new ArrayDecoder(CheckoutCode as Decoder<CheckoutCode>)
        })
        this.codes = response.data
    }

    createCode() {
        this.show(new ComponentWithProperties(CodeView, {
            code: CheckoutCode.create({}),
            isNew: true,
            callback: async (patch: PatchableArrayAutoEncoder<CheckoutCode>) => {
                await this.patch(patch)
            }
        }))
    }

    editCode(code: CheckoutCode) {
        this.show(new ComponentWithProperties(CodeView, {
            code,
            isNew: false,
            callback: async (patch: PatchableArrayAutoEncoder<CheckoutCode>) => {
                await this.patch(patch)
            }
        }))
    }

}
