









































































































































import { BackButton, CartFooter, CartItems, InputBox, List, ListItem, LoadingButton, NavigationBar, NumberInput,OrderDetails,Spinner, Toolbar } from "@goodless/components";
import { Session } from "@goodless/networking";
import { OrderData, PrivateInvoice, PrivateOrder } from "@goodless/structures";
import { Formatter } from "@goodless/utilities";
import { ArrayDecoder, Decoder, PatchableArray, PatchableArrayAutoEncoder } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
    components: {
        GeneralErrorsView,
        InputBox,
        Spinner,
        Toolbar,
        List,
        ListItem,
        NavigationBar,
        LoadingButton,
        BackButton,
        CartItems,
        CartFooter,
        OrderDetails,
        NumberInput
    }
})
export default class OrderView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    loading = false

    @Prop({ required: true })
    order!: PrivateOrder

    // Maps are not reactive in Vue 2
    returnedProducts: Record<string, number> = Object.fromEntries(this.order.data.returnedProducts)

    invoices: PrivateInvoice[] = []
    loadingInvoices = true

    loadingPatch = false

    get orderPdf() {
        return this.order.data.pdf?.getPublicPath()
    }

    get orderFormPdf() {
        return this.order.data.orderFormPdf?.getPublicPath()
    }

    formatPrice(price: number) {
        return Formatter.price(price)
    }

    mounted() {
        this.downloadInvoices().catch(console.error)
    }

    getReturnedProductAmount(id: string) {
        return this.returnedProducts[id] ?? 0
    }

    get openPrice() {
        return this.invoices.reduce((c, i) => c + (i.paidAt ? 0 : i.data.prices.totalWithVAT), 0)
    }

    setReturnedProductAmount(id: string, amount: number) {
        this.$set(this.returnedProducts, id, amount)
    }

     get didSendReturnedProducts() {
        return this.order.data.returnedProducts.size > 0
    }

    get warrantyMissing() {
        return this.order.data.calculateWarrantyMissing(new Map(Object.entries(this.returnedProducts)))
    }

    get missingVAT() {
        return Math.round(this.warrantyMissing * 21 / 100)
    }

    async saveOrder() {
        if (this.loadingPatch) {
            return
        }
        if (!confirm("Opgelet: je kan dit later niet ongedaan maken + deze actie verstuurt mogelijk een factuur of creditnota naar de klant")) {
            return
        }

        try {
            this.loadingPatch = true

            const patch = PrivateOrder.patch({ id: this.order.id })
            patch.data = OrderData.patch({
                returnedProducts: new Map(Object.entries(this.returnedProducts))
            })
            const response =  await Session.shared.authenticatedServer.request({
                method: "PATCH",
                path: "/dashboard/order/"+this.order.id,
                body: patch,
                decoder: PrivateOrder as Decoder<PrivateOrder>
            })
            this.order.set(response.data)
            await this.downloadInvoices()
        } catch (e) {
            console.error(e)
        }
        this.loadingPatch = false
    }

    async updateOrder() {
        try {
            const response =  await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/order/"+this.order.id,
                decoder: PrivateOrder as Decoder<PrivateOrder>
            })
            this.order.set(response.data)
        } catch (e) {
            console.error(e)
        }
    }

    async markPaid(invoice: PrivateInvoice) {
        const arr: PatchableArrayAutoEncoder<PrivateInvoice>= new PatchableArray()
        arr.addPatch(PrivateInvoice.patch({
            id: invoice.id,
            paidAt: new Date()
        }))
        await this.patchInvoices(arr)
        await this.updateOrder()
    }

    async patchInvoices(patch: PatchableArrayAutoEncoder<PrivateInvoice>) {
        try {
            this.loadingInvoices = true
            const response =  await Session.shared.authenticatedServer.request({
                method: "PATCH",
                path: "/dashboard/order/"+this.order.id+"/invoices",
                body: patch,
                decoder: new ArrayDecoder(PrivateInvoice as Decoder<PrivateInvoice>)
            })
            this.invoices = response.data
        } catch (e) {
            console.error(e)
        }
        this.loadingInvoices = false
    }

    async downloadInvoices() {
        try {
            this.loadingInvoices = true
            const response =  await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/order/"+this.order.id+"/invoices",
                decoder: new ArrayDecoder(PrivateInvoice as Decoder<PrivateInvoice>)
            })
            this.invoices = response.data
        } catch (e) {
            console.error(e)
        }
        this.loadingInvoices = false
    }

}
