































































import { InputBox, List, ListItem, LoadingButton,NavigationBar, Spinner, Toolbar } from "@goodless/components";
import { Category, Product, Version } from "@goodless/structures";
import { AutoEncoderPatchType, PatchableArray, PatchableArrayAutoEncoder, patchContainsChanges } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import ProductView from "./ProductView.vue";

@Component({
    components: {
        GeneralErrorsView,
        InputBox,
        Spinner,
        Toolbar,
        List,
        ListItem,
        NavigationBar,
        LoadingButton
    }
})
export default class CategoryView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    loading = false

    @Prop({ required: true })
    category: Category
    categoryPatch = Category.patch({})

    @Prop({ required: true })
    isNew: boolean

    @Prop({ required: true })
    callback: (patch: PatchableArrayAutoEncoder<Category>) => Promise<void>

    get patchedCategory() {
        return this.category.patch(this.categoryPatch)
    }

    addPatch(patch: AutoEncoderPatchType<Category>) {
        this.categoryPatch = this.categoryPatch.patch(patch)
    }

    get name() {
        return this.patchedCategory.name
    }

    set name(name: string) {
        this.categoryPatch = this.categoryPatch.patch({ name })
    }

    get products() {
        return this.patchedCategory.products
    }

    newProduct() {
        const product = Product.create({})
        this.show(new ComponentWithProperties(ProductView, {
            product,
            isNew: true,
            callback: async (patch: PatchableArrayAutoEncoder<Product>) => {
                await this.addPatch(Category.patch({
                    products: patch
                }))
            }
        }))
    }

    editProduct(product: Product) {
        this.show(new ComponentWithProperties(ProductView, {
            product,
            isNew: false,
            callback: async (patch: PatchableArrayAutoEncoder<Product>) => {
                await this.addPatch(Category.patch({
                    products: patch
                }))
            }
        }))
    }

    moveUp(product: Product, index: number) {
        if (index <= 0) {
            return
        }
        const next = this.products[index - 2]
        const p = Category.patch({})
        p.products.addMove(product.id, next?.id ?? null)
        this.addPatch(p)
    }

    moveDown(product: Product, index: number) {
        if (index >= this.products.length - 1) {
            return
        }
        const next = this.products[index + 1]
        const p = Category.patch({})
        p.products.addMove(product.id, next?.id ?? null)
        this.addPatch(p)
    }

    async save() {
        if (this.loading) {
            return
        }
        this.loading = true;
        this.errorBox = null;

        try {
            const arr: PatchableArrayAutoEncoder<Category> = new PatchableArray()
            if (this.isNew) {
                arr.addPut(this.patchedCategory)
            } else {
                this.categoryPatch.id = this.category.id
                arr.addPatch(this.categoryPatch)
            }
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    async deleteMe() {
        if (this.loading) {
            return
        }
        if (!confirm("Ben je zeker dat je deze categorie wilt verwijderen?")) {
            return
        }
        this.loading = true;
        this.errorBox = null;

        try {
            const arr: PatchableArrayAutoEncoder<Category> = new PatchableArray()
            if (this.isNew) {
                throw new Error("Not possible")
            } else {
                arr.addDelete(this.patchedCategory.id)
            }
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    shouldNavigateAway() {
        if (patchContainsChanges(this.categoryPatch, this.category, { version: Version })) {
            if (confirm("Ben je zeker dat je terug wilt zonder op te slaan?")) {
                return true
            } else {
                return false
            }
        }
        return true
    }
    
}
