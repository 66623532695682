

































import { BackButton,List, ListItem, NavigationBar, Spinner } from "@goodless/components";
import { Session } from "@goodless/networking";
import { CustomerPrivateSettings, CustomerSettings, PrivateCustomer } from "@goodless/structures";
import { ArrayDecoder, Decoder, PatchableArrayAutoEncoder } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

import CustomerView from "./CustomerView.vue";

@Component({
    components: {
        GeneralErrorsView,
        Spinner,
        List,
        ListItem,
        NavigationBar,
        BackButton
    }
})
export default class CustomersView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    loading = false
    customers: PrivateCustomer[] = []

    created() {
        this.load().catch(console.error)
    }

    async load() {
        this.loading = true

        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/customers",
                decoder: new ArrayDecoder(PrivateCustomer as Decoder<PrivateCustomer>)
            })
            this.customers = response.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false

    }

    async patch(patch: PatchableArrayAutoEncoder<PrivateCustomer>) {
        const response = await Session.shared.authenticatedServer.request({
            method: "PATCH",
            path: "/dashboard/customers",
            body: patch,
            decoder: new ArrayDecoder(PrivateCustomer as Decoder<PrivateCustomer>)
        })
        this.customers = response.data
    }

    createCustomer() {
        const customer = PrivateCustomer.create({
            settings: CustomerSettings.create({}),
            privateSettings: CustomerPrivateSettings.create({}),
        })
        this.show(new ComponentWithProperties(CustomerView, {
            customer,
            isNew: true,
            callback: async (patch: PatchableArrayAutoEncoder<PrivateCustomer>) => {
                await this.patch(patch)
            }
        }))
    }


    openCustomer(customer: PrivateCustomer) {
        this.show(new ComponentWithProperties(CustomerView, {
            customer,
            isNew: false,
            callback: async (patch: PatchableArrayAutoEncoder<PrivateCustomer>) => {
                await this.patch(patch)
            }
        }))
    }
}
