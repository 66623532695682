






























import { BackButton,List, ListItem, NavigationBar, Spinner } from "@goodless/components";
import { Session } from "@goodless/networking";
import { PriceListSettings, PrivatePriceList } from "@goodless/structures";
import { ArrayDecoder, Decoder, PatchableArrayAutoEncoder } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

import PriceListView from "./PriceListView.vue";

@Component({
    components: {
        GeneralErrorsView,
        Spinner,
        List,
        ListItem,
        NavigationBar,
        BackButton
    }
})
export default class PriceListsView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    loading = false
    priceLists: PrivatePriceList[] = []

    created() {
        this.load().catch(console.error)
    }

    async load() {
        this.loading = true

        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/price-lists",
                decoder: new ArrayDecoder(PrivatePriceList as Decoder<PrivatePriceList>)
            })
            this.priceLists = response.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
        
    }

    async patch(patch: PatchableArrayAutoEncoder<PrivatePriceList>) {
        const response = await Session.shared.authenticatedServer.request({
            method: "PATCH",
            path: "/dashboard/price-lists",
            body: patch,
            decoder: new ArrayDecoder(PrivatePriceList as Decoder<PrivatePriceList>)
        })
        this.priceLists = response.data
    }

    createPriceList() {
        const priceList = PrivatePriceList.create({
            settings: PriceListSettings.create({})
        })
        this.show(new ComponentWithProperties(PriceListView, {
            priceList,
            isNew: true,
            callback: async (patch: PatchableArrayAutoEncoder<PrivatePriceList>) => {
                await this.patch(patch)
            }
        }))
    }


    openPriceList(priceList: PrivatePriceList) {
        this.show(new ComponentWithProperties(PriceListView, {
            priceList,
            isNew: false,
            callback: async (patch: PatchableArrayAutoEncoder<PrivatePriceList>) => {
                await this.patch(patch)
            }
        }))
    }
}
