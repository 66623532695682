








































import { BackButton, InputBox, LoadingButton, NavigationBar, PriceInput, Spinner, Toolbar } from '@goodless/components';
import { CheckoutCode, Version} from '@goodless/structures';
import { PatchableArray, PatchableArrayAutoEncoder, patchContainsChanges } from '@simonbackx/simple-encoding';
import { ErrorBox, GeneralErrorsView, Validator } from '@simonbackx/simple-error-forms';
import { NavigationMixin } from '@simonbackx/vue-app-navigation';
import { Component, Mixins, Prop } from 'vue-property-decorator';

@Component({
    components: {
        GeneralErrorsView,
        InputBox,
        Spinner,
        Toolbar,
        NavigationBar,
        LoadingButton,
        PriceInput,
        BackButton,
    }
})
export default class CodeView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    loading = false

    @Prop({ required: true })
    code: CheckoutCode
    codePatch = CheckoutCode.patch({})

    validator = new Validator()

    @Prop({ required: true })
    isNew: boolean

    @Prop({ required: true })
    callback: (patch: PatchableArrayAutoEncoder<CheckoutCode>) => Promise<void>

    get patchedCode() {
        return this.code.patch(this.codePatch)
    }

    get codeValue() {
        return this.patchedCode.code
    }

    set codeValue(value: string) {
        this.codePatch = this.codePatch.patch({
            code: value,
        })
    }

    get amount() {
        return this.patchedCode.amount
    }

    set amount(value: number) {
        this.codePatch = this.codePatch.patch({
            amount: value,
        })
    }

    async save() {
        if (this.loading) {
            return
        }
        this.loading = true;
        this.errorBox = null;

        try {
            if (!await this.validator.validate()) {
                this.loading = false
                return
            }
            const patchableArray: PatchableArrayAutoEncoder<CheckoutCode> = new PatchableArray()
            if (this.isNew) {
                patchableArray.addPut(this.patchedCode)
            } else {
                this.codePatch.id = this.code.id
                patchableArray.addPatch(this.codePatch)
            }
            await this.callback(patchableArray)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    async deleteMe() {
        if (this.loading) {
            return
        }

        if (!confirm('Ben je zeker dat je deze klant wilt verwijderen?')) {
            return
        }

        this.loading = true;
        this.errorBox = null;

        try {
            const patchableArray: PatchableArrayAutoEncoder<CheckoutCode> = new PatchableArray()
            if (this.isNew) {
                throw new Error('Not possible')
            } else {
                patchableArray.addDelete(this.patchedCode.id)
            }
            await this.callback(patchableArray)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    shouldNavigateAway() {
        if (patchContainsChanges(this.codePatch, this.code, { version: Version })) {
            return confirm('Ben je zeker dat je terug wilt zonder op te slaan?')
        }
        return true
    }
}
