










































import { BackButton,List, ListItem, NavigationBar, Spinner } from "@goodless/components";
import { Session } from "@goodless/networking";
import { PrivateOrder, OrderType } from "@goodless/structures";
import { Formatter } from "@goodless/utilities";
import { ArrayDecoder, Decoder, PatchableArrayAutoEncoder } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

import OrderView from "./OrderView.vue";

@Component({
    components: {
        GeneralErrorsView,
        Spinner,
        List,
        ListItem,
        NavigationBar,
        BackButton
    }
})
export default class OrdersView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    loading = false
    orders: PrivateOrder[] = []

    created() {
        this.load().catch(console.error)
    }

    formatPrice(price: number) {
        return Formatter.price(price)
    }

    isDeliveryOrder(order: PrivateOrder) {
        return order.data.type === OrderType.Delivery
    }

    async load() {
        this.loading = true

        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/dashboard/orders",
                query: {
                    offset: 0,
                    limit: 500
                },
                decoder: new ArrayDecoder(PrivateOrder as Decoder<PrivateOrder>)
            })
            this.orders = response.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false

    }

    async patch(patch: PatchableArrayAutoEncoder<PrivateOrder>) {
        const response = await Session.shared.authenticatedServer.request({
            method: "PATCH",
            path: "/dashboard/orders",
            body: patch,
            decoder: new ArrayDecoder(PrivateOrder as Decoder<PrivateOrder>)
        })
        this.orders = response.data
    }


    openOrder(order: PrivateOrder) {
        this.show(new ComponentWithProperties(OrderView, {
            order,
        }))
    }

}
