





















































import { AddressInput, BackButton, Checkbox, InputBox, List, ListItem, LoadingButton, NavigationBar, PhoneInput, PriceInput, Spinner, Toolbar, VATInput } from "@goodless/components";
import { User, Version } from "@goodless/structures";
import { PatchableArray, PatchableArrayAutoEncoder, patchContainsChanges } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView, Validator } from "@simonbackx/simple-error-forms";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
    components: {
        GeneralErrorsView,
        InputBox,
        Spinner,
        Toolbar,
        List,
        ListItem,
        NavigationBar,
        LoadingButton,
        AddressInput,
        PriceInput,
        PhoneInput,
        BackButton,
        VATInput,
        Checkbox
    }
})
export default class UserView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    loading = false

    @Prop({ required: true })
    user: User
    userPatch = User.patch({})

    validator = new Validator()

    @Prop({ required: true })
    isNew: boolean

    @Prop({ required: true })
    callback: (patch: PatchableArrayAutoEncoder<User>) => Promise<void>

    mounted() {
        if (this.isNew) {
            this.shouldSetPassword = true
        }
    }

    async save() {
        if (this.loading) {
            return
        }
        this.loading = true;
        this.errorBox = null;

        try {
            if (!await this.validator.validate()) {
                this.loading = false
                return
            }
            const arr: PatchableArrayAutoEncoder<User> = new PatchableArray()
            if (this.isNew) {
                arr.addPut(this.patchedUser)
            } else {
                this.userPatch.id = this.user.id
                arr.addPatch(this.userPatch)
            }
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    async deleteMe() {
        if (this.loading) {
            return
        }

        if (!confirm("Ben je zeker dat je dit account wilt verwijderen?")) {
            return
        }

        this.loading = true;
        this.errorBox = null;

        try {
            const arr: PatchableArrayAutoEncoder<User> = new PatchableArray()
            if (this.isNew) {
                throw new Error("Not possible")
            } else {
                arr.addDelete(this.patchedUser.id)
            }
            await this.callback(arr)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    shouldNavigateAway() {
        if (patchContainsChanges(this.userPatch, this.user, { version: Version })) {
            if (confirm("Ben je zeker dat je terug wilt zonder op te slaan?")) {
                return true
            } else {
                return false
            }
        }
        return true
    }

    get patchedUser() {
        return this.user.patch(this.userPatch)
    }

    get firstName() {
        return this.patchedUser.firstName
    }

    set firstName(firstName: string) {
        this.userPatch = this.userPatch.patch({ firstName })
    }

    get lastName() {
        return this.patchedUser.lastName
    }

    set lastName(lastName: string) {
        this.userPatch = this.userPatch.patch({ lastName })
    }

    get email() {
        return this.patchedUser.email
    }

    set email(email: string) {
        this.userPatch = this.userPatch.patch({ email })
    }

    makeid(length) {
        var result           = '';
        var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
        }
        return result;
    }

    get shouldSetPassword() {
        return this.patchedUser.setPassword !== null
    }

    set shouldSetPassword(shouldSetPassword: boolean) {
        if (this.shouldSetPassword === shouldSetPassword) {
            return
        }
        this.userPatch = this.userPatch.patch({ setPassword: shouldSetPassword ? this.makeid(15) : null })
    }

    get password() {
        return this.patchedUser.setPassword ?? ""
    }

    set password(password: string) {
        this.userPatch = this.userPatch.patch({ setPassword: password })
    }


}
